import { Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import useApiRequest from "../../utils/helperFunctions";

const ChangePassword = () => {
  const [form] = useForm();
  const { apiRequest } = useApiRequest();

  const onFinish = async (values: any) => {
    try {
      console.log(values);

      const resp = await apiRequest({
        url: "change-password",
        method: "PUT",
        showMessage: false,
        payload: values,
      });
      console.log("change-password", resp);

      if (resp.status === 200) {
        message.success("Password changed successfully.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      } else {
        message.error(resp.data.message);
      }

      form.resetFields();
    } catch (e) {
      message.error("Old Password is incorrect.");
      console.log("Error:", e);
    }
  };

  return (
    <>
      <div className="mt-3">
        <div className="text-center pt-2 pb-2">
            <img src="/assets/images/password-icon.png" alt="" />
        </div>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="Current Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Enter your current password.",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Enter your new password.",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long.",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
                message: "Re-type the new password.",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your password" />
          </Form.Item>

          <button className="theme-btn w-100" type="submit">
            Submit
          </button>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
