import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useEffect, useState } from "react";

const ClientRatingSlider = () => {
  const contentStyle: React.CSSProperties = {
    color: "#000",
    lineHeight: "1",
    textAlign: "center",
    background: "#fff",
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="hp-container">
      <Carousel
        autoplay
        autoplaySpeed={4000}
        fade
        arrows={isMobile}
        draggable
        pauseOnHover
        adaptiveHeight
        dots={isMobile}
        nextArrow={<ArrowRightOutlined />}
        prevArrow={<ArrowLeftOutlined />}
        className="client-rating-slider"
      >
        <div>
          <div style={contentStyle}>
            <div className="review">
              <span className="review-text">
                “The Virtual staging from Stagobox is truly top notch, I have
                never seen such level of customisations”
              </span>
              <div className="star-rating">
                <img src="/assets/images/stars.svg" />
              </div>
              <div className="reviewer-name">- Mr. John Doe</div>
            </div>
          </div>
        </div>

        <div style={contentStyle}>
          <div className="review">
            <span className="review-text">
            "I've used several virtual staging services before, but Stagobox is on another level. The customizations they offer are incredible!"
            </span>
            <div className="star-rating">
              <img src="/assets/images/stars.svg" />
            </div>
            <div className="reviewer-name">- Mr. John Doe</div>
          </div>
        </div>

        <div style={contentStyle}>
          <div className="review">
            <span className="review-text">
            "The attention to detail and customization options from Stagobox are simply amazing. My listings have never looked better!"
            </span>
            <div className="star-rating">
              <img src="/assets/images/stars.svg" />
            </div>
            <div className="reviewer-name">- Mr. John Doe</div>
          </div>
        </div>

        <div style={contentStyle}>
          <div className="review">
            <span className="review-text">
            "Stagobox provides exceptional virtual staging services. Their customization options make each space look uniquely stunning!"
            </span>
            <div className="star-rating">
              <img src="/assets/images/stars.svg" />
            </div>
            <div className="reviewer-name">- Mr. John Doe</div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default ClientRatingSlider;
