import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

type ImpactProp = {
  impactData: {
    frontImage?: string,
    backImage?: string,
    icon?: string,
    title?: string,
    desc?: string
    
  }[]
};

const ImpactSection = ({impactData}:ImpactProp) => {
  return (
    <>
      <section className="what_impact_section">
        <h2 className="mb-5">What impact it will create</h2>
        <div className="hp-container">
          <div className="row">
            {impactData.map((data, index) => (
              <div className="col-md-4" key={index}>
                <div className="impactCard" data-aos="fade-up">
                  <div className="cardimages">
                    <img src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/${data.frontImage?data.frontImage:"Living_2_Before_1727632214.jpg"}`} className="w-100 mb-4 backImage imageimg" alt="" />
                    <img src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/${data.backImage?data.backImage:"Living_2_After_1727632320.jpg"}`} className="w-100 mb-4 frontImage imageimg" alt="" />
                  </div>
                  <div className="icon-card text-center pb-2 pt-2 mt-3">
                    <img
                      src={`/assets/images/${data.icon?data.icon:"features1.svg"}`}
                      alt="icon"
                      width={50}
                      height={50}
                    />
                    <p className="mb-3 mt-3">
                      <b>{data.title?data.title:"Improves visual appeal"}</b>
                    </p>
                    <p>{data.desc?data.desc:"Helps in showcasing stunning visuals and increasing appeal by up to 30%"}</p>
                  </div>
                </div>
              </div>
            ))};
          </div>
          <div className="text-center" data-aos="fade-up">
          <a href="/services/commercial/virtual-staging-form" style={{background: 'var(--theme-deep-green)'}} className="theme-btn long-btn">Get Started</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImpactSection;
