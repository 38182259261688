import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { Link } from "react-router-dom";

interface ServiceCardProps {
  imageFront: string; // Image to show on the front
  imageBack: string; // Image to show on hover (back image)
  title: string;
  description?: string;
  isPlaceOrder?: boolean;
  listItems?: any;
  pageUrl?: string;
  price?: string; // For photo editing services only, this field should be present. Otherwise, it should be absent.
  exploreBtn?: boolean;
  classes?: any; // Additional CSS classes to add to the service card container.
  perPhoto?:boolean
  btnTitle?:string;
  singleImage?:boolean;
  cardID?:string;
}

const ServiceCard = ({
  imageFront,
  imageBack,
  title,
  classes,
  description,
  isPlaceOrder = true,
  listItems,
  pageUrl,
  price,
  exploreBtn,
  btnTitle = "Place An Order",
  perPhoto = true,
  singleImage=false,
  cardID
}: ServiceCardProps) => {
  return (
    <div className={"services-box " + classes?.join(" ")} data-aos="fade-up" id={cardID}>
      <div className="flip-container">
        {/* <div className="flipper">
                    <div className="front">
                        <img className="w-100 serviceCardImage" src={imageFront} alt="Front Image" />
                    </div>
                    <div className="back">
                        <img className="w-100 serviceCardImage" src={imageBack} alt="Back Image" />
                    </div>
                </div> */}
        <div className="compare">
          {
            singleImage?
            (
              <img width="100%" src={imageFront} alt="Single Image"/>
            )
            :
            <>
            <ReactCompareSlider
              // changePositionOnHover
              handle={<img src="/dragButton.svg" alt="drag_button"/>}
              key={0}
              itemOne={<ReactCompareSliderImage src={imageBack} alt="Before" />}
              itemTwo={<ReactCompareSliderImage src={imageFront} alt="After" />}
            />
            <div className="beforeafter-tag">
              <span>Before</span>
              <span>After</span>
            </div>
            </>
             
          }
      
        </div>
      </div>
      <div className="text">
        <h3 className="mb-2" style={{paddingBottom:"8px ",fontSize:"32px"}}>{title}</h3>
        {description && (
          <p className="SWPVR_LIST" dangerouslySetInnerHTML={{ __html: description }}></p>
        )}
        {listItems && listItems.length > 0 && (
          <ul>
            {listItems.map((item: any, index: number) => (
              <li key={index} >{item}</li>
            ))}
          </ul>
        )}
        <div className="price-per-photo mb-3">
          {price && (
            <>
              {!perPhoto && 
              <span>Price</span>
              }
              <h3>${price}</h3>
              {perPhoto && 
              <span>Per Photo</span>}
              
            </>
          )}
        </div>
        {isPlaceOrder && (
          <Link
            style={{ width: "200px" }}
            className="theme-btn"
            to={pageUrl ? pageUrl : "/"}
          >
            {btnTitle?btnTitle:"Place An Order"}
          </Link>
        )}

        {exploreBtn && (
          <Link
            style={{ width: "190px" }}
            className="theme-btn"
            to={pageUrl ? pageUrl : "/"}
          >
            Explore it
          </Link>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
