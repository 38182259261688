import { Modal } from "antd";
import { useState } from "react";
import GallerySlider from "../Slider/GallerySlider";
import { designCardsData, threeDesignCard } from "../../utils/helperFunctions";

const DesignWeOfffer = ({cardData = 'full'}) => {

    const [sliderVisible, setSliderVisible] = useState(false);
    const [sliderImages, setSliderImages] = useState<string[]>([]);

    // Function to handle design card click
    const handleDesignCardClick = (images: any) => {
        setSliderImages(images);
        setSliderVisible(true);
    };

    const selectedDesignCards = cardData === 'three' ? threeDesignCard : designCardsData;
    return (
        <section>
            <div className="hp-container">
                <h2 data-aos="fade-up">Design Style we offer</h2>
                <p data-aos="fade-up" className="text-center">Check out the most popular design styles to suit your listing.<br /> Click on view examples to see samples and learn more about each style.</p>

                <div className="design-card-box mt-5">
                    <div className="row">
                        {selectedDesignCards.map((design, index) => (
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" key={index}>
                                <div className="design-card" onClick={() => handleDesignCardClick(design.images)}>
                                    <div className="design-image">
                                        <img src={design.thumbnail} alt="Design Image" className="w-100" />
                                    </div>
                                    <div className="design-text">
                                        <span>{design.name}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal
                title="Design Gallery"
                width="800px"
                open={sliderVisible}
                footer={null}
                onCancel={() => setSliderVisible(false)}
            >
                <GallerySlider images={sliderImages}/>
            </Modal>
        </section>
        
    )
};


export default DesignWeOfffer;