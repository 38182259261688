import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

const SlickSlider = ({data=[]}:any ) => {
    const settings = {
        dots: true,          // Show navigation dots
        infinite: false,      // Infinite scrolling
        speed: 500,          // Transition speed in ms
        slidesToShow: 1,     // Number of slides to show at once
        slidesToScroll: 1,   // Number of slides to scroll at once
        autoplay: false,      // Auto scroll
        draggable:false,
        swipe:false,
        autoplaySpeed: 2000, // Speed of autoplay in ms
        nextArrow:<img src="/assets/images/sliderButton_right.svg" />,
        prevArrow:<img src="/assets/images/sliderButton_left.svg" />,
       
    };

    return (
            <Slider {...settings}>
                {data.map((item:any)=>(
                    item
                ))}
            </Slider>
    );
};

export default SlickSlider;
