import { Link } from "react-router-dom";
import CaterIndustry from "../../Sections/CaterIndustry";
import DesignWeOfffer from "../../Sections/DesignWeOffer";
import FirstTime from "../../Sections/FirstTime";
import HomeBannerSlider from "../../Sections/HomeBannerSlider";
import HowItWorks from "../../Sections/HowItWorks";
import IDiff from "../../Sections/Idifferent";
import ServiceCard from "../../Sections/ServiceCard";
import BottomNav from "../../Layout/BottomNav";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ImpactSection from "../../Sections/Impactsection";
import { Carousel } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import SlickSlider from "../../Slider/SlickSlider";
// import './day.css'

const PEObjectRemoval = () => {
  const serviceCardsData = [
    {
      title: "Object/Item Removal",
      description:
        "Elevate your photos with our Object Removal Service, dedicated to enhancing the visual appeal of your images by seamlessly removing unwanted objects. We specialize in transforming photos by eliminating distractions and enhancing their aesthetic quality, ensuring a clean and professional look. <br/><br/>Discover the power of clear, clutter-free images that highlight your subject without distractions. Our meticulous editing process ensures that every removed object seamlessly integrates into the background, maintaining the natural flow and integrity of the scene.<br/><br/>Key Features:",
      listItems: [
        "<b>Professional Object Removal:</b>  Expert removal of unwanted elements to enhance photo quality.",
        "<b>Seamless Integration:</b> Objects are removed with precision, maintaining the natural look of the scene.",
        "<b>Quick Turnaround Time:</b> Get your enhanced photos back in 24-48 hours.",
        "<b>Unlimited Free Revisions:</b> Ensure your satisfaction with our revision policy.",
        "<b>Guaranteed Customer Satisfaction:</b> We prioritize delivering results that exceed your expectations.",
      ],
      price: "5",
    },
  ];

  const impactSectionData = [
    {
      frontImage: "Bedroom_After_1727688070.jpg",
      backImage: "Bedroom_Before_1727692903.jpg",
      icon: "paint.svg",
      title: "Clutter - Free View",
      desc: "Helps in Listing with clean, unobstructed visuals, increase buyer engagement by 20%",
    },
    {
      frontImage: "obj_removal_1727692935.jpg",
      backImage: "obj_removal_1_1727692962.jpg",
      icon: "castperson.svg",
      title: "Room Appear Larger",
      desc: "Helps in space looks more expensive, perceive property value increased by 25%",
    },
    {
      frontImage: "OR_2_After_1727693548.jpg",
      backImage: "OR_2_before_1727693849.jpg",
      icon: "meteor.svg",
      title: "Highlight Property's Strength",
      desc: "Helps removing distraction, makes unique architectural details to shine.",
    },
  ];

  return (
    <>
      {/* <div className="page_banner">
                <div className="page-title" data-aos="fade-up">
                    <h2>Object/Item Removal</h2>
                    <p>Object removal service in real estate photography seamlessly erases unwanted elements, enhancing property images for a pristine presentation.</p>
                </div>
            </div> */}

      <></>

      {/* <div className="banner-content">
        <h1 data-aos="fade-up">
          Unlock the Power of Virtual Staging in Real Estate!
        </h1>
        <div className="banner-content-links">
          <Link
            className="theme-btn"
            to="/services/residential/virtual-staging"
          >
            Explore Services
          </Link>
          <Link to="/try-for-free">Or, book a free trial</Link>
        </div>
      </div> */}

      <BottomNav />

      <div className="blur-background-box">
        <div className="hp-container">
          <div className="page-title pt-5 pb-5 mb-5" data-aos="fade-up">
            <h1>Object Removal</h1>
            <p>
              <b>
                Elevate your photos with our Object Removal Service, dedicated
                to enhancing the visual appeal of your images.
              </b>
            </p>

            <p>
              By seamlessly removing unwanted objects. We specialize in
              transforming photos by eliminating distractions and enhancing
              their aesthetic quality, ensuring a clean and professional look.
              Discover the power of clear, clutter-free images that highlight
              your subject without distractions. Our meticulous editing process
              ensures that every removed object seamlessly integrates into the
              background, maintaining the natural flow and integrity of the
              scene.
            </p>
          </div>

          <div className="detailed-box mt-5">
            <div className="detail-service-card">
              <div className="detailed-text" data-aos="fade-up">
                <h3>Object/Item Removal</h3>
                <ul className="mt-4">
                  <li>Quick Turnaround Time of 24-48 Hours</li>
                  <li>Super Rapid Delivery in just 12 hours</li>
                  <li>Enjoy unlimited Free Revisions</li>
                  <li>Improve marketability</li>
                  <li>100% Customization Guaranteed</li>
                  <li>Experience Professional Excellence</li>
                </ul>
                <div className="price-per-photo">
                  <h3>$8</h3>
                  <span>Per Photo</span>
                </div>
                <Link
                  to="/services/object-removal-form?key=PE_Object_Removal"
                  className="theme-btn w-100 mt-3"
                  data-aos="fade-up"
                >
                  Place an Order
                </Link>
              </div>
              {/* <div className="detailed-image" data-aos="fade-up"> */}
              <div style={{ width: "50%", height: "100%" }}>
                {/* <Carousel
                  autoplay
                  autoplaySpeed={3000}
                  arrows
                  nextArrow={<ArrowRightOutlined />}
                  prevArrow={<ArrowLeftOutlined />}
                  className="client-rating-slider"
                >
                  

                  

                 
                </Carousel> */}
                <SlickSlider data={[(<div key={1}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={2}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/CRbefore_1727635596.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/CRafter_1727635651.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>),( <div key={2}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={2}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-fotoaibe-1571469before_1727635737.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-fotoaibe-15714691after_1727635774.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>),(<div key={0}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={0}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/aislinn-spaman-5m3v4GBB82o-unsplashBefore_1727634839.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/aislinn-spaman-5m3v4GBB82o-unsplash1after_1727635441.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>)]}/>
              </div>
            </div>
          </div>

          {/* <section>
          <div className="floor-plan-cards" data-aos="fade-up">
            {serviceCardsData.map((card, index) => (
              <ServiceCard
                image="/assets/images/industry4.jpg"
                key={index} // Provide a unique key for each card
                title={card.title}
                listItems={card.listItems}
                description={card.description}
                price={card.price}
              />
            ))}
          </div>
        </section> */}
        </div>
        <ImpactSection impactData={impactSectionData} />

        {/* ========== How it works ========== */}
        <HowItWorks />
        <div className="hp-container">
          {/* First time */}
          <div data-aos="fade-up">
            <FirstTime />
          </div>

          {/* Design and offer */}
          {/* <DesignWeOfffer /> */}

          {/* Industry we are catering */}
          <div data-aos="fade-up">
            <CaterIndustry />
          </div>

          {/* How we are different from others */}
          {/* <div data-aos="fade-up">
            <IDiff />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PEObjectRemoval;
