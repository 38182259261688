import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate()
  const updateDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Update dimensions on resize
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="thanks-page" style={{display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        padding: "20px",}}>
      {/* Confetti Effect */}
      <Confetti width={windowDimensions.width} height={windowDimensions.height} />
      
      {/* Thank You Message */}
      <h4>
        ORDER SUCCESSFULLY PLACE
      </h4>
      <h1 className="animated-text" style={styles.message}>Thank You!</h1>
      <h4 style={styles.subtext}>
        For Choosing Us.
      </h4>
      <button className="theme-btn" onClick={()=>{
        navigate('/')
      }}>
        GO TO HOMEPAGE
      </button>
    </div>
  );
};

const styles = {
  
  message: {
    fontSize: "8rem",
    fontWeight: "bold",
    color: "#32ae96",
    marginBottom: "10px",
  },
  subtext: {
    fontFamily: 'Poppins !important' ,
    fontSize: "1.4rem",
    marginBottom:"1.5vw",
    color: "black",
  },
};

export default ThankYouPage;
