import { Link } from "react-router-dom";
import CaterIndustry from "../../Sections/CaterIndustry";
import DesignWeOfffer from "../../Sections/DesignWeOffer";
import FirstTime from "../../Sections/FirstTime";
import HomeBannerSlider from "../../Sections/HomeBannerSlider";
import HowItWorks from "../../Sections/HowItWorks";
import IDiff from "../../Sections/Idifferent";
import ServiceCard from "../../Sections/ServiceCard";
import BottomNav from "../../Layout/BottomNav";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ImpactSection from "../../Sections/Impactsection";
import { Carousel } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import CompareTable from "../../Sections/CompareTable";
import SlickSlider from "../../Slider/SlickSlider";
// import './day.css'

const CommImageEnhacement = () => {
  const serviceCardsData = [
    {
      title: "ImageEnhacement Enhancement",
      description:
        "Transform your photos with our ImageEnhacement Enhancement Service, designed to elevate the luminosity and vibrancy of your images. We specialise in enhancing photos by adjusting brightness and contrast to bring out their full visual potential, making them more engaging and appealing. <br/><br/>Discover the power of enhanced brightness that revitalises your photos, making them stand out with clarity and brilliance. Our meticulous adjustments ensure that every detail shines, capturing attention and leaving a lasting impression.",
      listItems: [
        "<b>Enhanced Brightness and Vibrancy:</b> Transform dull photos into vibrant, eye-catching images",
        "<b>Professional Adjustments:</b> Meticulous brightness and contrast adjustments for optimal visual impact.",
        "<b>Quick Turnaround Time:</b> Get your enhanced photos back in 24-48 hours.",
        "<b>Unlimited Free Revisions:</b> Ensure your satisfaction with our revision policy.",
        "<b>Guaranteed Customer Satisfaction:</b> We prioritize delivering results that exceed your expectations.",
      ],
      price: "5",
    },
  ];

  const impactSectionData = [
    {
      frontImage: "6_1727640750.jpg",
      backImage: "61_1727640719.jpg",
      icon: "features1.svg",
      title: "Improves visual appeal",
      desc: "Helps in showcasing stunning visuals and increasing appeal by up to 30%",
    },
    {
      frontImage: "Living_1_After_1727640799.jpg",
      backImage: "Living_1_before_1727640771.jpg",
      icon: "atomosphere.svg",
      title: "Enhance atmosphere",
      desc: "Elevates atmosphere, creating up to 50% more engaging and inviting visuals",
    },
    {
      frontImage: "1after_1727640387.jpg",
      backImage: "1before_1727640354.jpg",
      icon: "HD.svg",
      title: "High dynamic range blending",
      desc: "Enhances image quality by up to 60%, revealing every detail in vibrant clarity",
    },
  ];

  return (
    <>
      <></>

      {/* <div className="banner-content">
        <h1 data-aos="fade-up">
          Unlock the Power of Virtual Staging in Real Estate!
        </h1>
        <div className="banner-content-links">
          <Link
            className="theme-btn"
            to="/services/residential/virtual-staging"
          >
            Explore Services
          </Link>
          <Link to="/try-for-free">Or, book a free trial</Link>
        </div>
      </div> */}

      <BottomNav />

      <div className="blur-background-box">
        <div className="hp-container" data-aos="fade-up">
          <div className="page-title pt-5 pb-5 mb-5" data-aos="fade-up">
            <h1>Image Enhancement</h1>
            <p>
              <b>
                Transform your property photos with our premier image
                enhancement service, crafted to elevate the luminosity and
                vibrancy of your real estate images.
              </b>
            </p>

            <p>
              Our expert team adjusts brightness and contrast, replaces
              distracting elements like TVs, and perfects colors to ensure your
              listings stand out. We straighten images, sharpen details, and
              remove unwanted items, ensuring every photo showcases your
              property in the best light. From fixing lens distortion to adding
              a cozy fire, we handle every aspect to make your photos look
              fantastic.
            </p>
          </div>

          <div className="detailed-box mt-5">
            <div className="detail-service-card">
              <div className="detailed-text" data-aos="fade-up">
                <h3>Image Enhancement</h3>
                <ul>
                  <li>Quick Turnaround Time of 24-48 Hours</li>
                  <li>Super Rapid Delivery in just 12 hours</li>
                  <li>Enjoy unlimited Free Revisions</li>
                  <li>Enhanced Brightness and Vibrancy</li>
                  <li>100% Customization Guaranteed</li>
                  <li>Guaranteed Customer Satisfaction</li>
                  <li>Experience Professional Excellence</li>
                </ul>
                <div className="price-per-photo">
                  <h3>$1.5</h3>
                  <span>Per Photo</span>
                </div>
                <Link
                  to="/services/image-enhancement-form?key=Com_Image_Enhancement"
                  className="theme-btn w-100 mt-3"
                  data-aos="fade-up"
                >
                  Place an Order
                </Link>
              </div>
              {/* <div className="detailed-image" data-aos="fade-up"> */}
              <div style={{ width: "50%", height: "100%" }}>
                {/* <Carousel
                  autoplay
                  autoplaySpeed={3000}
                  arrows
                  nextArrow={<ArrowRightOutlined />}
                  prevArrow={<ArrowLeftOutlined />}
                  className="client-rating-slider"
                >
                  

                  

                 
                </Carousel> */}
                <SlickSlider data={[( <div key={2}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={2}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/Bedroom_3_Before_1727690537.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/Bedroom_3_Before_1727690537.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>),(<div key={1}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={2}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/Family_1_Before_1727692583.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/Family_1_After_1727692648.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>),(<div key={0}>
                    <div className="compare">
                      <ReactCompareSlider
                        // changePositionOnHover
                        key={0}
                        itemOne={
                          <ReactCompareSliderImage
                            style={{ minHeight: "380px" }}
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/1before_1727692508.jpg`}
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/1after_1727692530.jpg`}
                          />
                        }
                      />
                      <div className="beforeafter-tag">
                        <span>Before</span>
                        <span>After</span>
                      </div>
                    </div>
                  </div>)]}/>
              </div>
            </div>
          </div>
        </div>

        <ImpactSection impactData={impactSectionData} />

        {/* ========== How it works ========== */}
        <HowItWorks />

        <div className="hp-container">
          {/* First time */}
          <div data-aos="fade-up">
            <FirstTime />
          </div>

          {/* Design and offer */}
          <DesignWeOfffer />

          {/* Industry we are catering */}
          <div data-aos="fade-up">
            <CaterIndustry />
          </div>

          {/* How we are different from others */}
          <div data-aos="fade-up">
            <CompareTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommImageEnhacement;
