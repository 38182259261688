import React from "react";

const TermsCon = () => {
  return (
    <div className="hp-container mt-5" style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", padding: "20px" }}>
      <h1 style={{ textAlign: "center", color: "#333" }}>Stagobox Pvt Ltd: Terms & Conditions and Privacy Policy</h1>
      <p className="mb-4" style={{ textAlign: "center", fontStyle: "italic" }}>Last Updated: [12 Dec 2024]</p>

      <h2 style={{ color: "#2c3e50" }}>1. Terms & Conditions</h2>
      <p>
        This Terms & Conditions agreement ("Agreement") is entered into by and between Stagobox Pvt Ltd ("Stagobox," "we," "our," or "us") and you, the customer ("you," "your," or "client"). By accessing, using, or subscribing to our services, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our services.
      </p>

      <h3 style={{ color: "#34495e" }}>1.1 Services Provided by Stagobox</h3>
      <ul style={{ marginLeft: "20px" }}>
        <li>Virtual Staging – Adding furniture, decorations, or other elements to photos of vacant properties to make them more appealing to potential buyers.</li>
        <li>Commercial Virtual Staging – Custom virtual staging solutions for commercial properties.</li>
        <li>Virtual Renovation – Altering property photos to showcase renovations and potential property transformations.</li>
        <li>Occupied to Vacant – Removing existing furniture or objects in occupied homes to showcase a "vacant" version of the property.</li>
        <li>Day to Dusk – Transforming daylight property photos into dusk or twilight shots.</li>
        <li>Object Removal – Removing unwanted objects from photos.</li>
        <li>Floor Plans – Generating detailed and accurate floor plans based on provided property measurements or existing layouts.</li>
        <li>Graphics Designing – Business Card Design, Logo and Branding, Vector Design, Digital Design.</li>
      </ul>

      <h4 style={{ color: "#2c3e50" }}>A: Eligibility to Use Stagobox</h4>
      <p>
        To use the Stagobox Platform, you must be at least the age of majority in the jurisdiction where you reside. Access to the Platform by individuals under 16 years of age is strictly prohibited.
      </p>

      <h4 style={{ color: "#2c3e50" }}>B: Registration and Account Creation</h4>
      <p>
        Certain features of the Stagobox Platform may require you to create an account. By registering, you agree to provide accurate and complete information and to keep that information up to date. We will handle any personal data you provide during registration in accordance with our Privacy Policy.
      </p>

      <h4 style={{ color: "#2c3e50" }}>C: Privacy Policy</h4>
      <p>
        Your privacy is important to us. Our practices regarding the collection, use, and sharing of personal information are outlined in our Privacy Policy. By using the Stagobox Platform, you agree to the terms of the Privacy Policy, whether or not you have registered for an account.
      </p>

      <h4 style={{ color: "#2c3e50" }}>D: Acceptable Use Policy</h4>
      <p>
        When using the Stagobox Platform, you agree to behave respectfully and in compliance with all applicable laws. Further guidelines regarding acceptable use are provided in our Acceptable Use Policy.
      </p>

      <h4 style={{ color: "#2c3e50" }}>E: Account Termination</h4>
      <p>
        You can deactivate your Stagobox account at any time through your account settings. We may suspend or terminate your access to the Platform, temporarily or permanently, at our sole discretion, for any reason, without notice or liability. Violating Stagobox policies may result in account termination.
      </p>

      <h4 style={{ color: "#2c3e50" }}>F: Feedback and Suggestions</h4>
      <p>
        We appreciate your feedback on how to improve the Stagobox Platform. Feel free to share your suggestions by emailing us at <a href="mailto:contact@stagobox.com" style={{ color: "#2980b9" }}>contact@stagobox.com</a>. By submitting feedback, you grant us the right to use and disclose your feedback in any manner, without restriction or compensation.
      </p>

      <h4 style={{ color: "#2c3e50" }}>G: Permitted and Prohibited Uses</h4>
      <p>
        You may use the Stagobox Platform only for lawful purposes, in compliance with these terms. Specifically, you agree not to:
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>Use the Platform in any way that could disable, damage, or impair its functionality, or interfere with others' use.</li>
        <li>Use automated systems, such as bots or crawlers, to access or collect material from the Platform.</li>
        <li>Introduce any harmful or malicious software (such as viruses or malware) to the Platform.</li>
        <li>Attempt unauthorized access to any part of the Platform or its systems.</li>
        <li>Launch any denial-of-service or similar attacks on the Platform.</li>
        <li>Circumvent security features or methods designed to prevent unauthorized access.</li>
        <li>Use the Platform in a manner that competes with Stagobox's services or undermines its functionality.</li>
        <li>Harvest or scrape data from user profiles for commercial purposes, or sell such data.</li>
      </ul>

      <h3 style={{ color: "#34495e" }}>2. Privacy Policy</h3>
      <p>
        This Privacy Policy outlines how Stagobox Pvt Ltd collects, uses, and protects your personal data. By using our services, you consent to the practices described in this policy.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.1 Data Collection</h4>
      <p>
        Stagobox collects the following types of information:
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>Personal Information: This includes your name, email address, payment information, and any other details you provide when registering or using our services.</li>
        <li>Usage Data: Information on how you interact with our website or services, such as IP addresses, browser types, and pages visited.</li>
        <li>Cookies: We use cookies to enhance your browsing experience, track usage patterns, and provide personalized content.</li>
      </ul>

      <h4 style={{ color: "#2c3e50" }}>2.2 Data Use</h4>
      <p>
        We use your data for the following purposes:
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>Service Provision: To process transactions and deliver requested services.</li>
        <li>Account Management: To maintain and manage your account.</li>
        <li>Communication: To send important updates, notifications, or promotional material (if you’ve opted in).</li>
        <li>Service Improvement: To analyze user behavior and improve our platform’s functionality.</li>
      </ul>

      <h4 style={{ color: "#2c3e50" }}>2.3 Data Sharing</h4>
      <p>
        We do not sell or rent your personal data to third parties. However, we may share your data with trusted third-party service providers who help us deliver our services (e.g., payment processors, cloud hosting providers). These third parties are obligated to protect your data and only use it to perform specific services on our behalf.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.4 Data Protection</h4>
      <p>
        We implement physical, technical, and administrative security measures to protect your data from unauthorized access, theft, or loss. However, no system is entirely secure, and we cannot guarantee complete protection of your data.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.5 Data Retention</h4>
      <p>
        We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. After this period, your data will be deleted or anonymized. By accepting this you allow Stagobox Pvt. Ltd. use your property images for marketing purpose.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.6 Your Rights</h4>
      <p>
        You have the following rights regarding your personal data:
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>Access: You can request a copy of the data we hold about you.</li>
        <li>Correction: You can correct any inaccurate or incomplete data.</li>
        <li>Deletion: You can request the deletion of your data under certain circumstances.</li>
        <li>Opt-out: You can opt-out of receiving marketing emails at any time by following the unsubscribe link or contacting us directly.</li>
      </ul>

      <h4 style={{ color: "#2c3e50" }}>2.7 Children's Privacy</h4>
      <p>
        Our services are not intended for children under the age of 16. If we learn that we have collected personal information from children without appropriate consent, we will take steps to delete that information.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.8 Policy Changes</h4>
      <p>
        We reserve the right to update this Privacy Policy at any time. Any changes will be posted on our website, and the "Last Updated" date will be revised. Continued use of our services after such changes constitutes your acceptance of the updated policy.
      </p>

      <h4 style={{ color: "#2c3e50" }}>2.9 Contact Us</h4>
      <p>
        If you have any questions or concerns about our Privacy Policy or Terms & Conditions, please contact us at:
      </p>
      <address style={{ marginLeft: "20px" }}>
        Stagobox Pvt Ltd<br />
        Email: <a href="mailto:Contact@stagobox.com" style={{ color: "#2980b9" }}>Contact@stagobox.com</a><br />
        Phone: 9235659571<br />
        Address: Bangalore, India
      </address>
    </div>
  );
};

export default TermsCon;
