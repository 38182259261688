import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

const TestPage = () => {
    const settings = {
        dots: true,          // Show navigation dots
        infinite: false,      // Infinite scrolling
        speed: 500,          // Transition speed in ms
        slidesToShow: 1,     // Number of slides to show at once
        slidesToScroll: 1,   // Number of slides to scroll at once
        autoplay: false,      // Auto scroll
        draggable:false,
        swipe:false,
        autoplaySpeed: 1000, // Speed of autoplay in ms
    };

    return (
        <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
            <h2>React Slick Slider</h2>
            <Slider {...settings}>
            <ReactCompareSlider
            // changePositionOnHover
            handle={<img src="/dragButton.svg" alt="drag_button"/>}
            key={0}
            itemOne={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="Before" />}
            itemTwo={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694224232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="After" />}
          />
            <ReactCompareSlider
            // changePositionOnHover
            handle={<img src="/dragButton.svg" alt="drag_button"/>}
            key={0}
            itemOne={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="Before" />}
            itemTwo={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694224232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="After" />}
          />
            <ReactCompareSlider
            // changePositionOnHover
            handle={<img src="/dragButton.svg" alt="drag_button"/>}
            key={0}
            itemOne={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="Before" />}
            itemTwo={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694224232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="After" />}
          />
            <ReactCompareSlider
            // changePositionOnHover
            handle={<img src="/dragButton.svg" alt="drag_button"/>}
            key={0}
            itemOne={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="Before" />}
            itemTwo={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694224232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="After" />}
          />
            <ReactCompareSlider
            // changePositionOnHover
            handle={<img src="/dragButton.svg" alt="drag_button"/>}
            key={0}
            itemOne={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="Before" />}
            itemTwo={<ReactCompareSliderImage src={"https://images.unsplash.com/photo-1513694224232-719a280e022f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="After" />}
          />
            </Slider>
        </div>
    );
};

export default TestPage;
