import CommercialStagingForm from "./CommercialStagingForm"

const DigitalDisplayForm = () => {
    return (
        <>
            <CommercialStagingForm unitPrice={8} form_name="Digital Display Service" />
        </>
    )
}

export default DigitalDisplayForm;