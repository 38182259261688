import React, { useState, useEffect } from 'react';
import './paypal.css'
import useApiRequest from '../../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';



const queryParams = new URLSearchParams(window.location.search);

const url_orderId = queryParams.get('OrderId');
const url_service = queryParams.get('Service');
const url_amount = queryParams.get('Amount');
const url_orderType = queryParams.get('OrderType');
const url_countryCode = queryParams.get('CC') || 'USD';

const BreakDownContent = [
    {
        text: 'Date:',
        main: '--:--',
    },
    {
        text: 'Issuer:',
        main: 'Stagobox',
    },
    {
        text: 'Invoice No:',
        main: '--:--',
    },
    {
        text: 'Amount:',
        main: '--:--',
    },
];

const BreakDownEntry = ({ details }) => (
    <li>
        <span className={details.icon}></span>
        <div className="list-content">
            <p>
                {details.text}
                <span className="list-bold">{details.main}</span>
            </p>
        </div>
    </li>
);

const Breakdown = ({ breakdown }) => (
    <div className="card-breakdown">
        <div className="card-breakdown--header">
            <p>Receipt for</p>
            <h2>Stagobox</h2>
        </div>
        <ul className="card-breakdown--list">
            {breakdown.map((item, index) => (
                <BreakDownEntry key={index} details={item} />
            ))}
        </ul>
    </div>
);


const OverviewHeader = ({ logo }) => {
    const [currentTime, setCurrentTime] = useState({
        date: '',
        time: '',
    });

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
            });
            const formattedTime = now.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                // second: '2-digit',
                hour12: false,
                timeZoneName: 'short',
            });
            const [time, timeZone] = formattedTime.split(' ');
            setCurrentTime({ date: formattedDate, time: `${time} ${timeZone}` });
        };

        updateTime(); // Update immediately
        const intervalId = setInterval(updateTime, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <>
        <div className="overview-header">
            <img className="logo" src={logo} alt="Logo" />
            <div className="timestamp">
                <span>{currentTime.date}</span>
                <span>{currentTime.time}</span>
            </div>
            {/* <hr /> */}
        </div>
        <hr />
        </>
    );
};

const PurchaseOverview = ({ product, value }) => (
    <div className="purchase-overview">
        <h3>{product}</h3>
        <p>Total: {url_amount}</p>
    </div>
);

const OverviewBody = ({ product, value, name, merchant, merchantEmail }) => (
    <div className="overview-body">
        <div className="purchase-overview">
        <h3>{product}</h3>
        <p>Total: {url_amount || value}</p>
    </div>
        <div className="user-info">
            <p className="user-info-name">Hello {name || 'User'},</p>
            <span>Here is the payment link below : </span>
            <div id="paypal-button-container"></div>
            {/* <p className="user-info-text">
                You sent a payment of <span>{value}</span> to {merchant}. (
                <a href={`mailto:${merchantEmail}`}>{merchantEmail}</a>)
            </p> */}

        </div>
        <div className="descriptor">
            <p>It may take a few moments for this transaction to appear in your account</p>
        </div>
    </div>
);

const OverviewFooter = ({ invoiceId }) => (
    <footer className="overview-footer">
        <span className="site">
            <a href="https://halvorson.digital/blog" target="_blank" rel="noopener noreferrer">
                www.paypal.com/help
            </a>
        </span>
        {/* <span className="invoice-id">Invoice ID: {invoiceId}</span> */}
    </footer>
);

const Overview = (props) => (
    <div className="card-overview">
        <OverviewHeader logo="https://s3-us-west-2.amazonaws.com/s.cdpn.io/553128/PayPal.svg" />
        <OverviewBody {...props} />
        <OverviewFooter invoiceId={props.invoiceId} />
    </div>
);

const Paypal = (props) => {
    const navigate = useNavigate()
    const [breakdown, setBreakdown] = useState([]);
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);

    useEffect(() => {
        const dynamicBreakdown = [
            {
                text: 'Date:',
                main: props?.info?.date || new Date().toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                }),
            },
            {
                text: 'Issuer:',
                main: 'Stagobox',
            },
            {
                text: 'Invoice No:',
                main: props?.info?.orderId || url_orderId || 'N/A',
            },
            {
                text: 'Amount:',
                main: `$ ${props?.info?.totalPrice || url_amount || '0.00'} USD`,
            },
        ];

        setBreakdown(dynamicBreakdown);
    }, [props]);

    const { apiRequest } = useApiRequest();

    // Fetch service list from the API
    const handlePaymentDetail = async (data) => {
      try {
        const resp = await apiRequest({
          url: "payment-details",
          method: "POST",
          showMessage: true,
          payload: data,
        });
        if(resp.status === 201){
            navigate("/thank-you")
        }else {
            console.log("Failed to save payment details", resp);
            message.error("Failed to save payment details");
        }
        
      } catch (errors) {
        console.error("Failed to fetch service list", errors);
      }
    };


    console.log("Payment details ===>>>", props)

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://sandbox.paypal.com/sdk/js?client-id=AXb20tCQeZU5aJCjcjySl6kcVUXzgtIWl5szU691WT-AYVPshjGq8DAMRTyiXkXHED2lHGaKyZGRoVT6';
        script.async = true;
        script.onload = () => {
            setIsSdkLoaded(true);
        };
        script.onerror = () => {
            console.error('PayPal SDK script failed to load.');
        };
        document.body.appendChild(script);

        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    useEffect(() => {
        if (isSdkLoaded && window.paypal) {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            description: 'Stagobox',
                            amount: {
                                currency_code: props?.info?.countryCode || url_countryCode,
                                value: props?.info?.totalPrice || url_amount
                            }
                        }]
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order);

                    const obj = {};
                    obj.order_id = props?.info?.orderId; // The order ID
                    obj.invoice_id = order.id; // The same as the order ID in this case
                    obj.capture_id = order.purchase_units[0]?.payments?.captures[0]?.id; // Capture ID
                    obj.merchant_id = order.purchase_units[0]?.payee?.merchant_id; // Merchant ID
                    obj.merchant_email = order.purchase_units[0]?.payee?.email_address; // Merchant email
                    obj.shipping_full_name = order.purchase_units[0]?.shipping?.name?.full_name; // Full name for shipping
                    obj.shipping_address = `${order.purchase_units[0]?.shipping?.address?.address_line_1}, ${order.purchase_units[0]?.shipping?.address?.address_line_2 || ''}, ${order.purchase_units[0]?.shipping?.address?.admin_area_2}, ${order.purchase_units[0]?.shipping?.address?.admin_area_1}, ${order.purchase_units[0]?.shipping?.address?.postal_code}, ${order.purchase_units[0]?.shipping?.address?.country_code}`; // Full shipping address
                    obj.amount = order.purchase_units[0]?.amount?.value; // Total amount
                    obj.currency_code = order.purchase_units[0]?.amount?.currency_code; // Currency code
                    obj.status = order.status; // Order status
                    obj.payer_id = order.payer?.payer_id; // Payer ID
                    obj.payer_name = `${order.payer?.name?.given_name} ${order.payer?.name?.surname}`; // Full payer name
                    obj.payer_email = order.payer?.email_address; // Payer email
                    obj.payer_address = order.payer?.address?.country_code; // Payer country code (address)
                    obj.create_time = order.create_time; // Creation time
                    obj.update_time = order.update_time; // Update time
                    obj.complete_data = order; //

                    // console.log(obj);

                    handlePaymentDetail(obj);

                },
                onError: (err) => {
                    console.error('PayPal Buttons Error:', err);
                    message.error('Failed to create PayPal order due to a technical error. Try again later.');
                }
            }).render('#paypal-button-container');
        }
    }, [isSdkLoaded]);

    // useEffect(() => {
    //     setBreakdown(BreakDownContent);
    // }, []);

    return (
        <div className=''>
            <div className="paypal-card">
                {url_amount ? <></> : <Breakdown breakdown={breakdown} />}
                <Overview
                    product="Stagobox Account"
                    merchant="Stagobox"
                    merchantEmail="paypal@stagobox.com"
                    name={props?.details?.name || url_service}
                    value={`$ ${props?.info?.totalPrice || url_amount} USD`}
                    invoiceId={`$ ${props?.info?.orderId || url_orderId}`}
                />
            </div>
        </div>
    );
};

export default Paypal;
